export const ICON_SPRITE_URL =
  window.bawue2024.ICON_SPRITE_URL || '/media/icons/icons.svg';

export const LANG = document.documentElement.lang || 'de';

export const BREAKPOINTS = {
  s: ' 0px',
  m: ' 580px',
  l: ' 820px',
  xl: ' 1025px',
  xxl: ' 1090px',
};

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 580px)',
  l: '(min-width: 820px)',
  xl: '(min-width: 1025px)',
  xxl: '(min-width: 1090px)',
};

export const COOKIESTATUS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
};

export const COOKIES = {
  MARKETING: [
    {
      name: 'bawu-youtube',
      expires: undefined,
    },
    {
      name: 'bawu-instagram',
      expires: undefined,
    },
    {
      name: 'bawu-vimeo',
      expires: undefined,
    },
    {
      name: 'bawu-facebook',
      expires: undefined,
    },
    {
      name: 'bawu-twitter',
      expires: undefined,
    },
    {
      name: 'bawu-mastodon',
      expires: undefined,
    },
    {
      name: 'bawu-soundcloud',
      expires: undefined,
    },
    {
      name: 'bawu-datawrapper',
      expires: undefined,
    },
  ],
  CONSENT: [
    {
      name: 'bawu-consent',
      expires: undefined,
    },
    {
      name: 'fontsLoaded',
      expires: undefined,
    },
  ],
};
